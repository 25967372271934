const STAGING_URL = 'https://api.optitrac.com/steelserv-staging';
const PRODUCTION_URL = 'https://api.optitrac.com/steelserv-production';

export enum Endpoint {
  GetLoads = '/shift-loader/loads',
  getTrafficLightStatus = '/shift-loader/traffic-light',
  getTrafficLightTimeline = '/shift-loader/traffic-light/timeline',
  getTimelineLoads = '/shift-loader/timeline',
  getTrucks = '/dust-control/api/trucks/steel-serv-trucks',
  getEngineHours = '/engine-hours/calculate/multiple-trucks',
  getParkedTimeByZones = '/KPI/park-time-by-zone',
  getSpeedEvents = `/asset-tracking/KPI/speed-events`,
  getAssetEvents = `/asset-tracking/KPI/daily-trip-summary`,
  getMaxSpeedEvents = `/asset-tracking/KPI/trips`,
  getGetListTemplatesEvents = `/template`,
  getMaterialMovementLoads = `/material-movements/api/loads`,
  getShiftLoaderTimelines = `/shift-loader/traffic-light/timeline`,
  getShiftLoaderLoads = `/shift-loader/loads`,
  getPreStartChecks = `/prestart/kpi`,
  getWaterConsumptions = `/water-cart/KPI/water-consumption-by-zone`,
  getWaterIn = `/water-cart/KPI/water-consumption-by-zone`,
  getWaterOut = `/dust-control/api/waterRefill`,
  getBinsPickupCount = `/hook-truck/KPI/pickup-bins-count`,
  getParkingZones = '/KPI/parking-zones',
  getParkingEvent = '/KPI/parking-events',
}

export const getUrl = (endpoint?: Endpoint) => {
  let baseUrl = '';
  if (process.env.REACT_APP_ENV === 'staging') baseUrl = STAGING_URL;
  else baseUrl = PRODUCTION_URL;
  if (endpoint) baseUrl += endpoint;
  else baseUrl += '/';
  return baseUrl;
};
