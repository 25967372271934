import { Reports } from '@optitrac/components';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../service/userContext';
import { Department } from './report.type';
import { toast } from 'react-toastify';
import { Asset, GroupedAsset } from '../../../utils/types';
import { fetch as customFetch } from '../../../utils/api';
import { API_METHODS } from '../../../dustControl/utils/constants';
import {
  Endpoint,
  getUrl,
} from '../../../materialMovements/pages/shiftLoader/helper';
import { useNavigate } from 'react-router-dom';
import { groupAssetsByCategory } from '../../../utils/helper';
import { LicenseData } from '../types';

const ReportsPage = () => {
  const [assetData, setAssetData] = useState<GroupedAsset[]>([]);
  const { departments } = useContext(UserContext);
  const { licenses }: { licenses: LicenseData[] } = useContext(UserContext);

  const departmentOption = departments.map((item: Department) => ({
    name: item.name,
    value: String(item.code),
  }));

  const navigate = useNavigate();
  const handleNavigate = (): void => navigate('/');

  useEffect(() => {
    const fetchAllTrucks = async (): Promise<void> => {
      try {
        const response = await customFetch<Asset[]>({
          method: API_METHODS.GET,
          endPoint: Endpoint.getTrucks,
          navigateToLoginPage: () => navigate('/'),
        });

        const assetResponse: Asset[] = response.data;
        const assets = groupAssetsByCategory(assetResponse);
        setAssetData(assets);
      } catch (error: any) {
        toast.error(error?.message);
      }
    };

    fetchAllTrucks();
  }, []);

  return (
    <Reports
      licensesData={licenses}
      departmentData={departmentOption}
      assetData={assetData}
      fetch={customFetch}
      speedEventsApiEndpoint={getUrl(Endpoint.getSpeedEvents)}
      navigateToLoginPage={handleNavigate}
      assetEventsApiEndpoint={getUrl(Endpoint.getAssetEvents)}
      maxSpeedEventsApiEndpoint={getUrl(Endpoint.getMaxSpeedEvents)}
      getParkingzoneEventApiEndPoint={getUrl(Endpoint.getParkingZones)}
      publicLambdaBaseUrl={getUrl()}
      getParkingEventApiEndPoint={getUrl(Endpoint.getParkingEvent)}
      getListTemplatesApiEndpoint={getUrl(Endpoint.getGetListTemplatesEvents)}
    />
  );
};

export default ReportsPage;
